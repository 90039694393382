import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, UserService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { PageTypeEnum } from '@portal-app/enums/page-type.enum';
import { ProjectTypeEnum } from '@portal-app/enums/project-type.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import { INextAsset } from '@portal-app/interfaces/next/next-asset.interface';
import { NextImagePipe } from '@portal-app/pipes/next-image.pipe';
import { ReplaySubject, firstValueFrom, switchMap } from 'rxjs';
import { AppInitService } from '../app-init.service';
import { HtmlService } from '../html.service';
import { NavbarService } from '../navbar.service';
import { PageSettings } from './interfaces/page-settings.interface';
import { ContentPage } from './interfaces/umbraco-page.interface';

@Injectable({
  providedIn: 'root',
})
export class PageService implements OnDestroy {
  private page = new ReplaySubject<ContentPage>(1);
  contentPage$ = this.page.asObservable();

  private pageSettings = new ReplaySubject<PageSettings>(1);
  pageSettings$ = this.pageSettings.asObservable();

  private pageLoadSubscription = this.pageSettings$.pipe(switchMap((x) => this.loadPage(x))).subscribe();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private appInitService: AppInitService,
    private router: Router,
    private nextImagePipe: NextImagePipe,
    private htmlService: HtmlService,
    private navbarService: NavbarService,
    private translateService: TranslateService
  ) {}

  setPageSettings(pageSettings: PageSettings) {
    this.pageSettings.next(pageSettings);
  }

  async loadPage(pageSettings: PageSettings) {
    await firstValueFrom(
      this.apiService.getUmbraco<ContentPage>(
        `v3/alineaportal/contentpage/fetch/${this.userService.userType}/${pageSettings.pageId}/${
          this.appInitService.siteConfiguration.PortalId ?? ''
        }`
      )
    )
      .then((page: ContentPage) => {
        if (this.appInitService.siteConfiguration.ProjectType === 'PrintBar') {
          if (page.pageType === PageTypeEnum.PsAssignmentLibrary) {
            page.pageType = PageTypeEnum.PrintbarAssignmentLibrary;
          }
        }

        this.page.next(page);

        if (
          page.pageType === PageTypeEnum.ContentPage ||
          page.pageType === PageTypeEnum.FrontPage ||
          page.pageType === PageTypeEnum.Error ||
          !page.pageType
        ) {
          this.router.navigate([PageTypeEnum.ContentPage], {
            skipLocationChange: true,
          });
        } else {
          this.router.navigate([page.pageType], {
            skipLocationChange: true,
          });
        }
      })
      .catch((e: HttpErrorResponse) => {
        if (pageSettings.fromError) {
          this.showError(pageSettings.pageUrl, 503);
          return;
        }
        const annualPlanUrl = 'annual-plan';
        const studentAnswersUrl = 'student-answers';
        const favoritesUrl = 'favorites';
        const libraryUrl = 'library';
        const courseLibraryUrl = 'course-library';
        const bookLibraryUrl = 'book-library';

        if (
          pageSettings.pageId === annualPlanUrl ||
          pageSettings.pageId === studentAnswersUrl ||
          pageSettings.pageId === favoritesUrl ||
          pageSettings.pageId === libraryUrl ||
          pageSettings.pageId === courseLibraryUrl ||
          pageSettings.pageId === bookLibraryUrl
        ) {
          const timeout = setTimeout(() => {
            this.showError(pageSettings.pageUrl, 404);
          }, 2000);

          if (pageSettings.pageId === annualPlanUrl) {
            this.navbarService.courseAnnualPlanUrl$.subscribe((url) => {
              clearTimeout(timeout);
              this.router.navigate([url]);
            });
          } else if (pageSettings.pageId === studentAnswersUrl) {
            this.navbarService.courseStudentAnswersUrl$.subscribe((url) => {
              clearTimeout(timeout);
              this.router.navigate([url]);
            });
          } else if (pageSettings.pageId === favoritesUrl) {
            this.navbarService.favoritesUrl$.subscribe((url) => {
              clearTimeout(timeout);
              this.router.navigate([url]);
            });
          } else if (
            pageSettings.pageId === libraryUrl ||
            pageSettings.pageId === courseLibraryUrl ||
            pageSettings.pageId === bookLibraryUrl
          ) {
            this.navbarService.libraryUrl$.subscribe((url) => {
              clearTimeout(timeout);
              this.router.navigate([url]);
            });
          }
        } else {
          this.showError(pageSettings.pageUrl, e.status);
        }
      });
  }

  async showError(pageUrl: string, statusCode: number) {
    let tempStatusCode = statusCode.toString();

    if (!tempStatusCode) {
      tempStatusCode = '500';
    }

    if (tempStatusCode === '503') {
      this.appInitService.setDefaultSiteConfiguration();
      // what project styling should be used if it doesnt know what project type it is?
      this.appInitService.setStylesheet(ProjectTypeEnum.Fagportal);

      const coverImage: INextAsset = {
        Dimensions: { Width: 1440, Height: 550 },
        FileType: 'png',
        Id: 'XengyaELEeuUsygYeGSXWg',
        Path: '2021-04/sxj1MjTsS57x/Cover-3',
        Title: 'BookBox Cover 3',
        Type: 'Image',
      };

      const htmlGridEditor: any = {
        type: SectionTypeEnum.RichTextEditor,
        html: this.htmlService.fixHtml(`<p>${this.translateService.instant('Page.503.SectionText')}</p>`),
      };

      this.page.next({
        hero: {
          textAlign: 'center',
          sectionAlign: 'center',
          headline: 'Der skete desværre en fejl :(',
          // description: 'Sitet kunne ikke loades',
          width: 'medium',
          backgroundImageUrl: this.nextImagePipe.transform(coverImage, 1920),
          // IsNextCoverImage: true,
        },
        sectionsV2: [
          {
            columns: [
              {
                gridEditors: [htmlGridEditor],
                width: 100, //'fullWidth', TODO
              },
            ],
            alignment: 'center',
            width: 'small',
          },
        ],
      });
      await this.router.navigate([PageTypeEnum.Error503], {
        skipLocationChange: true,
      });
      return;
    }
    if (tempStatusCode === '400') {
      // Thomas Olsen wants to handle 400 as 404
      tempStatusCode = '404';
    }

    let errorPageId = this.appInitService.getErrorPage(tempStatusCode);
    if (!errorPageId) {
      errorPageId = this.appInitService.getErrorPage('404');
    }
    if (tempStatusCode && errorPageId) {
      this.setPageSettings({
        pageId: errorPageId.toString(),
        pageUrl: pageUrl,
        fromError: true,
      });
    }
  }

  ngOnDestroy() {
    this.pageLoadSubscription?.unsubscribe();
  }
}
